import { useCallback, useMemo, useState } from "react";
import { wakeUpPdfService } from "./pdfService";
import { requireOk } from "~/services/fetchApi";
import { alertError } from "~/utils/alert/alertUtils";
import { pdfApiUrl } from "~/config";

export interface PdfDownloadProps {
  url: string;
}

export function usePdfDownload({ url }: PdfDownloadProps) {
  const [fetching, setFetching] = useState(false);

  useMemo(() => {
    wakeUpPdfService();
  }, []);

  const startFetch = useCallback(() => {
    if (!pdfApiUrl) {
      alertError("Invalid configuration.");
      return null;
    }

    if (process.env.NODE_ENV === "development") {
      console.debug("generate pdf", url);
    }

    setFetching(true);
    fetch(pdfApiUrl + "/create", {
      method: "POST",
      body: JSON.stringify({
        url,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(requireOk)
      .then((value) => value.blob())
      .then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      })
      .catch((reason) => {
        console.error(reason);
        alertError(reason);
      })
      .then(() => {
        setFetching(false);
      });
  }, [url]);
  return useMemo(() => {
    return {
      fetching,
      startFetch,
    };
  }, [startFetch, fetching]);
}
