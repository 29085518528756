import React from "react";
import styled from "@emotion/styled";
import PdfStatsButton from "../../modules/pdf/PdfStatsButton";

interface PdfTestProps {}

function PdfTest(props: PdfTestProps) {
  return (
    process.env.NODE_ENV === "development" && (
      <Container>
        <PdfStatsButton projectId={94} title={"Download 94"} />
        <PdfStatsButton projectId={43} title={"Download 43"} />
      </Container>
    )
  );
}

const Container = styled.div`
  margin: 24px;
  button {
    margin-right: 12px;
    &[disabled] {
      opacity: 0.5;
    }
  }
`;

// noinspection JSUnusedGlobalSymbols
export default PdfTest;
