import React from "react";
import Button, { ButtonProps } from "../../components/button/Button";
import { PdfDownloadProps, usePdfDownload } from "./usePdfDownload";

export interface PdfButtonProps
  extends PdfDownloadProps,
    Pick<ButtonProps, "disabled" | "variant"> {
  title: string;
}

function PdfButton({ title, ...props }: PdfButtonProps) {
  const { fetching, startFetch } = usePdfDownload(props);

  return (
    <Button
      {...props}
      onClick={startFetch}
      disabled={fetching || props.disabled}
      loading={fetching}
    >
      {title}
    </Button>
  );
}

export default PdfButton;
