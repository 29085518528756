import { pdfApiUrl } from "../../config";
import { requireOk } from "../../services/fetchApi";

let isUp = false;
export function wakeUpPdfService() {
  if (isUp) return Promise.resolve(true);
  return fetch(`${pdfApiUrl}/ping`)
    .then(requireOk)
    .then(() => {
      isUp = true;
      return true;
    })
    .catch((reason) => {
      console.error(reason);
    });
}
